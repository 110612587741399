import React, { useState } from 'react';
import IframeResizer from 'iframe-resizer-react';
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from '@mui/material';

const vendorSupportForms = [
  '240677927725166',
  '241935365534158',
  '241935755294164',
  '241935694666169',
  '242357933127156',
];

const JotForm = ({
  changeSelectedVendor,
  formData,
  category,
  email,
  jotformId,
  subcategory,
  vendors,
  selectedVendor,
}) => {
  const [otherVendorName, setOtherVendorName] = useState();
  const {
    firstName,
    lastName,
    storeName,
    cell,
    department,
    role,
    defaultStore,
  } = formData;

  let jotformStoreId = defaultStore,
    jotformStoreName = storeName,
    storeEmail = `mendo${defaultStore}@mendocinofarms.com`,
    vendorEmail,
    vendorEmail1,
    vendorEmail2,
    vendorEmail3,
    vendorEmail4,
    vendorEmail5,
    vendorContact,
    vendorAccount,
    vendorAccountName,
    vendor;
  if ((role === 'DO' && !defaultStore) || role === 'Basecamp') {
    jotformStoreId = formData.defaultStore;
    jotformStoreName = `${firstName} ${lastName}`;
    storeEmail = email;
  }
  if (selectedVendor && formData.defaultStore) {
    const vendorInfo = vendors.find(
      ({ store, vendor }) =>
        String(store) === String(formData.defaultStore) &&
        String(vendor) === String(selectedVendor)
    );
    if (vendorInfo) {
      vendorEmail = vendorInfo.emailAddress;
      const emailArray = vendorEmail.split(',');
      [vendorEmail1, vendorEmail2, vendorEmail3, vendorEmail4, vendorEmail5] =
        emailArray;
      vendorContact = vendorInfo.contactName;
      vendorAccount = vendorInfo.account;
      vendorAccountName = vendorInfo.accountName;
      vendor = selectedVendor;
    }
    if (selectedVendor === 'Other Vendor') {
      vendorEmail = '';
      vendorContact = otherVendorName;
      vendor = otherVendorName;
    }
  }
  const encodedSource = `https://form.jotform.com/${jotformId}?email=${email}&name[first]=${encodeURIComponent(
    firstName
  )}&name[last]=${encodeURIComponent(lastName)}&cell=${encodeURIComponent(
    cell
  )}&role=${encodeURIComponent(role)}&department=${encodeURIComponent(
    department
  )}&storeName=${
    jotformStoreName ? encodeURIComponent(jotformStoreName) : ''
  }&storeId=${
    jotformStoreId ? encodeURIComponent(jotformStoreId) : ''
  }&supportDepartment=${category}&storeEmail=${storeEmail}&subDepartment=${
    subcategory ? encodeURIComponent(subcategory) : ''
  }${vendor ? `&vendor=${vendor}` : ''}${
    vendorEmail ? `&vendorEmail=${vendorEmail}` : ''
  }${vendorEmail1 ? `&vendorEmail1=${vendorEmail1}` : ''}${
    vendorEmail2 ? `&vendorEmail2=${vendorEmail2}` : ''
  }${vendorEmail3 ? `&vendorEmail3=${vendorEmail3}` : ''}${
    vendorEmail4 ? `&vendorEmail4=${vendorEmail4}` : ''
  }${vendorEmail5 ? `&vendorEmail5=${vendorEmail5}` : ''}${
    vendorContact ? `&vendorContact=${vendorContact}` : ''
  }${vendorAccount ? `&vendorAccount=${vendorAccount}` : ''}${
    vendorAccountName ? `&vendorAccountName=${vendorAccountName}` : ''
  }`;
  const jotformHTML = `
<iframe
  id="JotFormIFrame-${jotformId}"
  title="Form"
  onload="window.parent.scrollTo(0,0)"
  allowtransparency="true"
  allowfullscreen="true"
  allow="geolocation; microphone; camera"
  src="${encodedSource}"
  frameborder="0"
  style="min-width: 100%; max-width: 100%; height: 750px; border: none"
  scrolling="no"
>
</iframe>
<script type="text/javascript">
  var ifr = document.getElementById('JotFormIFrame-${jotformId}');
  if (ifr) {
    var src = ifr.src;
    var iframeParams = [];
    if (window.location.href && window.location.href.indexOf('?') > -1) {
      iframeParams = iframeParams.concat(
        window.location.href
          .substr(window.location.href.indexOf('?') + 1)
          .split('&')
      );
    }
    if (src && src.indexOf('?') > -1) {
      iframeParams = iframeParams.concat(
        src.substr(src.indexOf('?') + 1).split('&')
      );
      src = src.substr(0, src.indexOf('?'));
    }
    iframeParams.push('isIframeEmbed=1');
    ifr.src = src + '?' + iframeParams.join('&');
  }
  window.handleIFrameMessage = function (e) {
    if (typeof e.data === 'object') {
      return;
    }
    var args = e.data.split(':');
    if (args.length > 2) {
      iframe = document.getElementById(
        'JotFormIFrame-' + args[args.length - 1]
      );
    } else {
      iframe = document.getElementById('JotFormIFrame');
    }
    if (!iframe) {
      return;
    }
    switch (args[0]) {
      case 'scrollIntoView':
        iframe.scrollIntoView();
        break;
      case 'setHeight':
        iframe.style.height = args[1] + 'px';
        if (
          !isNaN(args[1]) &&
          parseInt(iframe.style.minHeight) > parseInt(args[1])
        ) {
          iframe.style.minHeight = args[1] + 'px';
        }
        break;
      case 'collapseErrorPage':
        if (iframe.clientHeight > window.innerHeight) {
          iframe.style.height = window.innerHeight + 'px';
        }
        break;
      case 'reloadPage':
        window.location.reload();
        break;
      case 'loadScript':
        if (!window.isPermitted(e.origin, ['jotform.com', 'jotform.pro'])) {
          break;
        }
        var src = args[1];
        if (args.length > 3) {
          src = args[1] + ':' + args[2];
        }
        var script = document.createElement('script');
        script.src = src;
        script.type = 'text/javascript';
        document.body.appendChild(script);
        break;
      case 'exitFullscreen':
        if (window.document.exitFullscreen) window.document.exitFullscreen();
        else if (window.document.mozCancelFullScreen)
          window.document.mozCancelFullScreen();
        else if (window.document.mozCancelFullscreen)
          window.document.mozCancelFullScreen();
        else if (window.document.webkitExitFullscreen)
          window.document.webkitExitFullscreen();
        else if (window.document.msExitFullscreen)
          window.document.msExitFullscreen();
        break;
    }
    var isJotForm = e.origin.indexOf('jotform') > -1 ? true : false;
    if (
      isJotForm &&
      'contentWindow' in iframe &&
      'postMessage' in iframe.contentWindow
    ) {
      var urls = {
        docurl: encodeURIComponent(document.URL),
        referrer: encodeURIComponent(document.referrer),
      };
      iframe.contentWindow.postMessage(
        JSON.stringify({ type: 'urls', value: urls }),
        '*'
      );
    }
  };
  window.isPermitted = function (originUrl, whitelisted_domains) {
    var url = document.createElement('a');
    url.href = originUrl;
    var hostname = url.hostname;
    var result = false;
    if (typeof hostname !== 'undefined') {
      whitelisted_domains.forEach(function (element) {
        if (
          hostname.slice(-1 * element.length - 1) === '.'.concat(element) ||
          hostname === element
        ) {
          result = true;
        }
      });
      return result;
    }
  };
  if (window.addEventListener) {
    window.addEventListener('message', handleIFrameMessage, false);
  } else if (window.attachEvent) {
    window.attachEvent('onmessage', handleIFrameMessage);
  }
</script>
`;
  let showVendorSelector = vendorSupportForms.indexOf(String(jotformId)) > -1;
  return (
    <>
      {showVendorSelector ? (
        <Grid
          style={{
            marginTop: 16,
            marginBottom: 8,
          }}
          justifyContent={'center'}
          container
          xs={12}
          spacing={1}
        >
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <InputLabel>{'Select a vendor'}</InputLabel>
              <Select
                disabled={!vendors || !formData.defaultStore}
                value={selectedVendor}
                onChange={changeSelectedVendor}
                label={'Select a vendor'}
                name='vendor'
                style={{
                  background: 'white',
                  textAlign: 'left',
                }}
              >
                {vendors
                  ?.filter(
                    ({ store }) =>
                      String(store) === String(formData.defaultStore)
                  )
                  .map((vendor, i) => (
                    <MenuItem key={vendor.vendor} value={vendor.vendor}>
                      {vendor.vendor}
                    </MenuItem>
                  ))}
                <MenuItem value={'Other Vendor'}>Other Vendor</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      ) : null}
      {selectedVendor === 'Other Vendor' && (
        <Grid
          style={{
            marginTop: 16,
            marginBottom: 8,
          }}
          justifyContent={'center'}
          container
          xs={12}
          spacing={1}
        >
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              label='Name of Vendor'
              name='otherVendorName'
              value={otherVendorName}
              style={{ backgroundColor: 'white' }}
              onChange={(e) => setOtherVendorName(e.target.value)}
              required
            />
          </Grid>
        </Grid>
      )}
      <IframeResizer
        title='Form Name'
        srcDoc={jotformHTML}
        style={{
          width: '100%',
          // marginTop: -46,
          height: '6000px',
          border: 'none',
        }}
      />
    </>
  );
};

export default JotForm;
